import React, { ReactElement } from 'react';
import dynamic from 'next/dynamic';
import Error404Component from '@/components/404';
import Layout from '@/layout/Layout';
import { useRouter } from 'next/router';

// const Layout = dynamic(() => import('@/layout/Layout'));
const HocHead = dynamic(() => import('@/components/HOC/HocHead'));
// const Error404Component = dynamic(() => import('@/components/404'), {
//   ssr: false,
// });

const ErrorPage = () => {
  const router = useRouter();
  const pageTitle: any = router?.query?.slug;

  const hocHeadData = {
    meta: {
      title: pageTitle?.replaceAll('-', ' ') || "We couldn't find any matches!",
      description: `We have a better option for "${pageTitle?.replaceAll('-', ' ')}". Discover similar products at Wooden Street.`,
    },
  };

  return (
    <>
      <HocHead seo={hocHeadData} />
      <Error404Component />
    </>
  );
};

ErrorPage.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};

export default ErrorPage;
